import type { Metadata } from "next"

export const IS_PRODUCTION: boolean = process.env.NODE_ENV === "production"
export const REVALIDATION_TAGS = {
  articles: ["article"],
  blog: ["category", "article"],
  categories: ["category"],
  global: ["global"],
  pages: ["page", "featurecampaign"],
  page: ["page"],
}
export const GOOGLE_MAPS_API_KEY: string | undefined =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
export const BUGSNAG_API_KEY: string | undefined =
  process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
export const TWITTER: Metadata["twitter"] = {
  card: "summary_large_image",
  site: "@prokodo_app",
}
export const OPEN_GRAPH: Metadata["openGraph"] = {
  siteName: "prokodo",
}
export const FALLBACK_META: Metadata = {
  title: "prokodo - End the strife of social life",
  description:
    "Ready for new friendships? prokodo finds them. Meet people in your area with whom you can share your interests and expand your social horizon.",
}
